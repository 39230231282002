<app-modal *ngIf="ready$ | async" [info]="info" (closeModal)="closeModal($event)">
  <ng-template #submitWithClothingContent>
    <button
      *ngIf="freeTariff && !limitAccess"
      id="m-plan-trial-action-btn"
      [routerLink]="[actionRouterLink]"
      [queryParams]="freeTariff && !limitAccess ? { tryModal: 'open' } : {}"
      (click)="closeModal(true)"
      class="btn small btn--transparent modal-btn">
      <span class="btn__text">{{ actionFreeTryText | translate }}</span>
    </button>
    <button
      id="m-plan-limitation-action-btn"
      [routerLink]="[actionRouterLink]"
      [queryParams]="freeTariff && !limitAccess ? { planModal: 'open' } : {}"
      (click)="closeModal(true)"
      class="btn btn--new-primary small bright modal-btn">
      <span class="btn__text">
        {{ actionPaymentText | translate }}
      </span>
    </button>
  </ng-template>
</app-modal>
