export interface TariffDto {
  discount?: number;
  grains?: number;
  month?: number;
  monthUserPrice?: number;
  price?: number;
  users?: number;
  exportCredits?: number;

  name?: TariffName;
  active?: boolean;
  custom?: boolean;
  notShowDiscount?: boolean;
}

export interface TariffsByMonthsAsset {
  [month: number]: TariffDto[];
}

export interface TariffGettingInfo {
  currentTariffName?: TariffName;
  nextTariffName?: TariffName;
  currentTariff?: TariffDto;
  nextTariff?: TariffDto;
  isCurrentTariffBigger?: boolean;
  currentTariffIndex?: number;
  nextTariffIndex?: number;
  isNextTariffNotChangeable?: boolean;
  pricingAsset?: TariffsByMonthsAsset;
}

export interface ChangeTariffMessageParams {
  price?: number;
  monthsExpression?: string;
  usersExpression?: string;
  date?: string;
}

export interface ChangeTariffMonthInfo {
  monthNumber: number;
  activeTariffIndex: number;
}

export type TariffName = 'free' | string;

export enum TariffBtnEnum {
  TRY_FREE_WEEK_TRIAL = 'try-free-week-trial',
  PAY_FOR_PLAN = 'pay-for-plan'
}
export interface ChangeTariffInfo {
  currentTariffName: string;
  nextTariffName: TariffName;
  pricingAsset: TariffsByMonthsAsset;
  isUserFree: boolean;
  btnType?: TariffBtnEnum;
}

export interface NextTariffInfoInitialData {
  pricingAsset?: TariffsByMonthsAsset;
  tariffs?: TariffDto[];
  nextTariffParsedInfo: TariffDto;
  nextTariffName: string;
  getApproximateTariff?: boolean;
}
