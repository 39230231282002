import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { LangCode, Locale } from './app/shared/models';
import { multiLangLinkInfo } from './app/shared/config';

if (environment.production) {
  if (environment.name === 'prod') {
    appendIndexMetaToHead();
    // appendFacebookMetaToHeaпd(); temporary hid
    appendGoogleTagToHead();
    appendGoogleTagLayerToHead();
    // appendMetaPixelCode(); temporary hid
  }
  
  appendMultiLangLink();
  enableProdMode();
} else {
  appendNoIndexMetaToHead();
}

appendFirstPromoterScriptToHead();


platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));

if (history) {
  history.scrollRestoration = 'manual';
}

function appendGoogleTagToHead(): void {
  const script: HTMLScriptElement = document.createElement('script');
  script.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','GTM-KPQRKZ58');`;

  script.setAttribute('data-html2canvas-ignore', 'true');
  document.head.appendChild(script);
}

function appendGoogleTagLayerToHead() {
  const script: HTMLScriptElement = document.createElement('script');
  const script2: HTMLScriptElement = document.createElement('script');

  script.setAttribute('async', 'async');
  script.src = 'https://www.googletagmanager.com/gtag/js?id=G-ZY00DVYL2N';
  script.setAttribute('data-html2canvas-ignore', 'true');

  script2.innerHTML = `window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag(‘js’, new Date());
  gtag(‘config’, ‘G-ZY00DVYL2N’);`;
  script2.setAttribute('data-html2canvas-ignore', 'true');

  document.head.appendChild(script);
  document.head.appendChild(script2);
}

function appendIndexMetaToHead(): void {
  const meta: HTMLMetaElement = document.createElement('meta');

  meta.name = 'googlebot';
  meta.content = 'index, follow';

  document.head.appendChild(meta);
}

function appendNoIndexMetaToHead(): void {
  const meta: HTMLMetaElement = document.createElement('meta');

  meta.name = 'googlebot';
  meta.content = 'noindex';

  document.head.appendChild(meta);
}

function appendFacebookMetaToHead(): void {
  const meta: HTMLMetaElement = document.createElement('meta');

  meta.name = 'facebook-domain-verification';
  meta.content = '3hugtvxrdz4vr13xeabsqi32fzh3gf';

  document.head.appendChild(meta);
}

function appendMultiLangLink(): void {
  Object.keys(multiLangLinkInfo)?.forEach((locale: Locale) => {
    const link: HTMLLinkElement = getLangAltLink(
      multiLangLinkInfo[locale].langCode,
      multiLangLinkInfo[locale].href
    );

    document.head.appendChild(link);
  });
}

function appendMetaPixelCode(): void {
  const script: HTMLScriptElement = document.createElement('script');
  script.innerHTML = `!function(f,b,e,v,n,t,s)
    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window, document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');
    fbq('init', '520696043247341');
    fbq('track', 'PageView');`;

  script.setAttribute('data-html2canvas-ignore', 'true');
  document.head.appendChild(script);
}

function getLangAltLink(langCode: LangCode, href: string): HTMLLinkElement {
  const link: HTMLLinkElement = document.createElement('link');

  link.rel = 'alternate';
  link.hreflang = langCode;
  link.href = href;

  return link;
}

function appendFirstPromoterScriptToHead(): void {
  const firstScript: HTMLScriptElement = document.createElement('script');
  firstScript.innerHTML = `(function(w){w.fpr=w.fpr||function(){w.fpr.q = w.fpr.q||[];w.fpr.q[arguments[0]=='set'?'unshift':'push'](arguments);};})(window);
fpr("init", {cid:"wf0ngu1f"});
fpr("click");`;
  const secondScript: HTMLScriptElement = document.createElement('script');
  secondScript.async = true;
  secondScript.src = 'https://cdn.firstpromoter.com/fpr.js';

  document.head.appendChild(firstScript);
  document.head.appendChild(secondScript);
}
