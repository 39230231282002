// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { SystemEnvironment } from '../app/shared/models';

export const environment: SystemEnvironment = {
  production: false,
  name: 'test',
  backEndUrl: 'https://test.wandify.io/api',
  stripePublishableKey:
    'pk_test_51NpoHUKmiloxdvB2rL18FwiUCoWAuKhJVB47gBtCvrXoX5clQWOSnrW09184kLec1TakNEM4JJe0zaxxKjDp2PW300Gg8udaGw',
  defaultLocale: 'en',
  extensionOrigin: 'chrome-extension://ffgojlndpbfcpgpeibknijnjjajoiada',
  appIdIntercom: 'tllz0ki4',
  extensionLink:
    'https://chrome.google.com/webstore/detail/wandify-extension/jhaaipbjdjpkcdlmdepackpalbhjcfib',
  extensionId: 'lpcbkojimoiehglcmfjbailnddajekam',
  socialLinks: {
    linkedIn: 'https://www.linkedin.com/company/wandify/',
    facebook: '#',
    youtube: 'https://www.youtube.com/@Wandifyio',
    G2: 'https://www.g2.com/products/wandify/reviews'
  },
  wandifyBlogLink: 'https://wandify-blog.allmatics.net/',
  apiDocumentationLink: 'https://wandifyio.github.io/api/',
  firstPromoterLink: 'https://partner.wandify.io/',
  cleverStaffIntegrationLink: 'https://dev.cleverstaff.net/!#/integration-page'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
